import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing3/Hero";
import Content1 from "../sections/landing1/Content1";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <Hero />
        <Content1 />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
