import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgAboutB from "../../assets/image/theme/frozen-berries.jpeg";
import imgAboutM from "../../assets/image/theme/frozen_vegetables.jpeg";
import imgAboutP from "../../assets/image/l5/png/l5-about-pattern.png";
import imgAboutS from "../../assets/image/theme/frozen_mushrooms.jpeg";

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <div className="content-section bg-default-4 pt-lg-25 pt-15 pb-10 pb-lg-15">
      <Container>
        <Row>
          <Col xl="12" lg="12" md="10">
            <div className="section-title mb-13">
              <h4 className="pre-title gr-text-12 text-red text-uppercase mb-9">
                Our Story
              </h4>
              <h2 className="title gr-text-4 heading-color">
              KARDEF Group is young and flexible trading company, operating in the area of B2B trade with frozen fruits, vegetables, mushrooms, milk and whey powders, and sugar. Even if young, we are building upon long family business experience in the field of production of fruit semi-finished products for dairy and bakery industry.
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <div
              className="about-image img-big pt-lg-13"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <img className="w-100 rounded-10" src={imgAboutB} alt="" />
            </div>
          </Col>
          <Col lg="7">
            <Row className="justify-content-between align-items-center ps-lg-13">
              <Col md="6">
                <div
                  className="about-image img-mid mt-9 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <img className="w-100 rounded-10" src={imgAboutM} alt="" />
                  <div
                    className="abs-pattern gr-abs-tr-custom"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                    data-aos-delay="300"
                  >
                    <img src={imgAboutP} alt="" />
                  </div>
                </div>
              </Col>
              <Col md="5">
                <div
                  className="about-image img-sm mt-9 mt-lg-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img className="rounded-10" src={imgAboutS} alt="" />
                </div>
              </Col>
              <Col xl="10">
                <div className="about-content mt-12 mt-lg-23">
                  <p className="gr-text-9 text-color-opacity">
                  Our goal is to build stable and long-lasting relationships with both our customers and suppliers, where trust is a main factor.
                  </p>
                  <p className="gr-text-9 text-color-opacity">
                  We always strive to provide our customer with the product they need, not what we need to sell. Communication and understanding the needs of our customers is of utmost importance to us.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Content1;
